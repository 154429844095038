export interface Application {
  id: string;
  name: string;
  description?: string;
  type: ApplicationType;
  customerId?: string;
  state: ApplicationState;
  datasourceCount?: number;
  region?: string;
  lock?: LockSate;
}

export enum LockStates {
  LOCKED = 'LOCKED',
  UNLOCKED = 'UNLOCKED',
  LOCKING = 'LOCKING',
  UNLOCKING = 'UNLOCKING',
}

export interface LockSate {
  state: LockStates;
  reason: string;
}

export enum ApplicationType {
  SITE_SEARCH = 'SITE_SEARCH',
  MACHINE_LEARNING = 'MACHINE_LEARNING',
  APP_STUDIO = 'APP_STUDIO',
  AI_ENDPOINT = 'AI_ENDPOINT',
  SIGNAL_STORE = "SIGNAL_STORE"
}

export const AppTypeMap = {
  [ApplicationType.SITE_SEARCH]: 'Connected Search',
  [ApplicationType.MACHINE_LEARNING]: 'Lucidworks AI',
  [ApplicationType.APP_STUDIO]: 'App Studio',
  [ApplicationType.SIGNAL_STORE]: 'Signal Store'
};

export enum ApplicationState {
  REQUESTED = 'REQUESTED',
  READY_TO_CONFIGURE = 'READY_TO_CONFIGURE',
  ACTIVE = 'ACTIVE',
  DEACTIVATING = 'DEACTIVATING',
  INACTIVE = 'INACTIVE',
  ERROR = 'ERROR',
  UNKNOWN = 'UNKNOWN',
}

export interface UIConfig {
  authClientId: string;
  authIssuerUri: string;
  apiBasePath: string;
  launchDarklyEnvId: string;
  googleAnalyticsEnabled: boolean;
  googleAnalyticsTrackingId?: string;
  domain?: string;
}

export type SignalTypes = 'visit' | 'query' | 'click' | 'cart-add' | 'purchase';

export interface SignalStatsCount {
  signal_type: SignalTypes
  signal_count: number;
  application_id: string;
}
 
export interface SignalCounts {
  series: SignalStatsCount[];
  series_total: number;
}

export interface User {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  fullName?: string;
  status?: string;
  creationDate?: string;
  roles?: Role[];
}

export interface UserRoleView extends User {
  role: RoleType;
  roleMetaData?: { role: RoleType; customerAdmin: boolean; selectionDisabled: boolean };
  customerAdmin?: boolean;
  selectionDisabled?: boolean;
  app?: Application;
}

export interface Region {
  name: string;
  description: string;
  availability: string;
  visibility: RegionVisibility;
}

export type CellType = 'DATA' | 'FETCH' | 'TRAINING';
export type RegionVisibility = 'PUBLIC' | 'PRIVATE';
export type RoleLevel = 'APPLICATION' | 'CUSTOMER';
// TODO: remove this type as we are not going to maintain any knowledge of type of role in the UI anymore
export type RoleType = 'admin' | 'user' | 'NO ACCESS';

export interface Customer {
  id: string;
  name: string;
  capabilities: string[];
}

export interface Role {
  level: RoleLevel;
  id: string;
  role: RoleType | any;
  roleType?: 'SPRINGBOARD' | 'INTEGRATION';
  ownerId?: string;
  email?: string;
}

export interface CustomerRoleView extends Role {
  name?: string;
  selectionDisabled?: boolean;
}

export interface ApplicationRoleView extends Role {
  name?: string;
  roleMetaData?: { role: string; selectionDisabled: boolean };
  selectionDisabled?: boolean;
  appType?: ApplicationType;
  selectionOptions?: any[];
}

export interface CustomModelRoleView extends Role {
  roleMetaData?: { role: string; selectionDisabled: boolean };
  selectionDisabled?: boolean;
}

export interface IntegrationRoleView extends Role {
  name: string;
  integrations: Integration[];
  templateId: string;
  roleMetaData?: { role: string; selectionDisabled: boolean };
  selectionDisabled?: boolean;
}

export type MachineLearningModelStates = 'TRAINING' | 'TRAINING_FAILED' | 'AVAILABLE';
export type MachineLearningDeploymentStates = 'DEPLOYING' | 'DEPLOY_FAILED' | 'DEPLOYED' | 'DELETING' | 'DELETE_FAILED';

export interface CustomMachineLearningModel {
  id: string;
  modelType: string;
  vectorSize: number;
  category: string;
  description: string;
  region: string;
  trainingData: {
    catalog: string;
    signals: string;
  };
  config: {};
  state: MachineLearningModelStates;
  deployments?: CustomMachineLearningDeployment[];
  trainingStarted: string;
  trainingCompleted?: string;
  trainingMetrics?: {
    summary: {};
    epoch_metrics: {};
    final_metrics: {};
  };
  createdBy: string;
  name: string;
  errorMessage?: string;
}

export interface CustomMachineLearningDeployment {
  name: string,
  id: string;
  modelId: string;
  region: string;
  minReplicas?: number;
  maxReplicas?: number;
  state: MachineLearningDeploymentStates;
  deployedAt: string;
  lastUsed?: string;
}

export interface NewDeploymentFormData {
  modelId: string;
  region: string;
  minReplicas: number;
  maxReplicas: number;
}

export interface ServiceAccountCredentials {
  credentials: {
    serviceAccountKey: string;
  };
  urls: string[];
}

export interface Integration {
  id: string;
  name: string;
  tags: string[];
  clientId: string;
  loginUrls: string[];
  logoutUrls?: string[];
  template: Template;
  creationDate: string;
  application: Application;
  customer: Customer;
}

export interface Template {
  id: string;
  name: string;
  description: string;
  resourceLevel: 'APPLICATION' | 'CUSTOMER';
  code: string;
}

export interface SpringboardRoleMeta {
  id: string;
  name: string;
  description: string;
  resourceLevel: 'APPLICATION' | 'CUSTOMER';
  workloadType?: {
    domain: string;
    type: string;
  };
}

export interface IntegrationRoleTemplateMeta extends SpringboardRoleMeta {
  resourceLevel: RoleLevel;
}

export interface IntegrationRoles {
  integrationTemplate: IntegrationRoleTemplateMeta;
  roles: Array<Omit<SpringboardRoleMeta, 'resourceLevel'>>;
}

export interface SupportedUserRoles {
  springboardRoles: SpringboardRoleMeta[];
  integrationRoles: IntegrationRoles[];
}

export enum SpringboardRoleTypes {
  APPLICATION = 'APPLICATION',
  CUSTOMER = 'CUSTOMER',
}

export interface NewModelFormData {
  name: string;
  modelType: string;
  region: string;
  trainingData: {
    signals: string;
    catalog: string;
  };
  config?: {
    'trainer_config.encoder_config.rnn_names_list': string[];
    'trainer_config.encoder_config.rnn_units_list': number[];
    'trainer_config/text_processor_config': string;
  };
  trainingDataCredentials?: {
    serviceAccountKey: string;
  };
}

export interface DeploymentLastUsed {
  timestamp_latest: string;
}

export interface Capability {
  id: string;
  displayName: string;
  description: string | null;
  domain: string | null;
  aliases: string[];
  capabilities: Capability[];
  topLevel: boolean;
}

export interface ManagedFusion {
  id: string;
  name: string;
  environment: string;
  notes: string;
  tags: string[];
  type: string;
  urlFragment: string;
  useCase: string;
  fusionVersion: string;
  solrVersion: string;
  k8sVersion: string;
  region: FusionEnvironment;
  loginUrls: string[];
  logoutUrls: string[];
  externalMetricsLink: string;
  customer: Pick<Customer, 'id' | 'name'>
}

export interface FusionEnvironment {
  vendorId: string;
  regionId: string;
  location: string;
}

export interface BaseSelfHostedFusionIntegration {
  id: string,
  name: string,
  environment: string,
  tags?: string[],
  type?: string,
  apiUrl: string,
}

export interface SelfHostedFusionIntegration extends BaseSelfHostedFusionIntegration {
  fusionVersion: string,
  solrVersion: string,
  connectionStatus: string,
  createdDate: string,
  jwksUrl: string,
  issuerUrl: string
}

export enum fusionInstanceTypes {
  MANAGED_FUSION = 'MANAGED_FUSION',
  SELF_HOSTED_FUSION = 'SELF_HOSTED_FUSION'
}

export interface OIDCClientUserSSOForm {
  name: string,
  id?: string,
  emailDomains: string[],
  clientId: string,
  clientSecret: string,
  configurationUrl: string,
  customDataFields?: {
    issuerUrl: string
    authorizationUrl: string,
    tokenUrl: string,
    userinfoUrl: string,
    jwksUrl: string,
  }
}

export interface OIDCClientUserSSOResponse {
  id: string,
  name: string,
  type: string,
  customer: Customer,
  emailDomains: string[],
  state: string,
  description: string,
  configurationUrl: string,
  customDataFields: {
    issuerUrl: string,
    authorizationUrl: string,
    tokenUrl: string,
    userinfoUrl: string,
    jwksUrl: string,
  },
  credentials: {
    clientId: string,
    clientSecret: string,
  },
  identityProviderAuthorizeUrl: string,
  identityProviderRedirectUri: string,
}

export interface SAMLClientUserSSOForm {
  name: string, 
  id?: string,
  emailDomains: string[],
  metadataUrl: string,
  customDataFields?: {
    ssoUrl: string,
    issuerUrl: string
    certificate: string,
  }
}

export interface SAMLClientUserSSOResponse {
  id: string,
  name: string,
  type: string,
  customer: Customer,
  emailDomains: string[],
  state: string,
  description: string,
  metadataUrl: string,
  customDataFields: {
    ssoUrl: string,
    issuerUrl: string,
    certificate: string,
  }
  identityProviderAssertionConsumersServiceUrl: string,
  identityProviderAudienceUri: string
}

export interface ExperienceManagerInstance {
  id: string;
  region: string;
  name: string;
  state: string; //TODO: Get enum from backend and as a type here
  fusionInstance: string;
  fusionAppId: string;
}

export interface ServiceAccountRequest {
  name: string;
  description: string;
  tags: string[];
  type: string;
  roles?: Role[];
}

export interface ServiceAccount {
  id: string;
  name: string;
  description: string;
  customer: Customer;
  creationDate: string;
  createdBy: string;
  tags: string[];
  type: string;
  roles?: Role[];
  clientId: string;
  clientSecret: string;
}

export interface ServiceAccountForm {
  name: string;
  type: string;
  description?: string;
  tags?: string[];
  id?: string;
}

export interface ClientCredentials {
  clientId: string,
  clientSecret: string,
  authorizationServer: string
}

export interface LucidworksAIIntegration {
  application: {
    id: string,
    name: string,
  },
  clientId: string,
  creationDate: string,
  customer: Customer,
  id: string,
  integratedSystemIds?: [],
  loginUrls: [],
  logoutUrls: [],
  name: string,
  tags: [],
  template: {
    authorizationFlows: {
      grantTypes: [],
      id: string,
      name: string,
      scopes: [],
    }[],
    code: string,
    description: string,
    id: string,
    name: string,
    resourceLevel: string
  }
}